import { ui } from '../components/wookie';
const dev = false;

const ORA =
  'https://g3c32193a94f6bf-bwhabita.adb.sa-saopaulo-1.oraclecloudapps.com/ords/habita';

const BWAPI = 'https://api.bewook.com';

const setup = {
  name: 'Marcelo Hamann',
  whatsapp: '12997777374',
  phone: '12997777374',
  phone_formatted: '(12) 99777-7374',
  email: 'credito@hamanncorp.com',
  title: 'HamannCorp',
  instagram: 'hamanncorp',
  twitter: 'hamanncorp',
  telegram: 'hamanncorp',
};

const config = {
  tenant: 'HamannCorp',
  company: 'HamannCorp Financiamentos',
  brand: 'hamanncorp',
  headline: 'Financiamentos Imobiliários',
  description: 'Financiamentos imobiliários e crédito bancário ágil e seguro',
  site: 'hamanncorp.com',
}
if (dev) config.brand = 'localhost';

const { tenant, company, brand, headline, site } = config;

const meta = {
  title: `${company} - ${headline}`,
  type: 'HomeAndConstructionBusiness',
  imageLink: `https://www.${site}/logotext512.png`,
  imageType: 'image/png',
  imageWidth: 180,
  imageHeight: 162,
  description:
    'A melhor consultoria habitacional do Vale do Paraíba especializada em financiamento e crédito bancário, crédito com garantia de imóvel e compra com FGTS.',
  keywords:
    'correspondente bancário, canal de atendimento, correspondente, serviços, pontos de atendimento, taubaté, financiamento imobiliário, consultoria financeira, crédito imobiliário, habitação, compra de imóveis, financiamento de casa, financiamento de apartamento, simulador de crédito, Caixa Econômica, Santander, Itaú, Bradesco, Banco Inter, aprovação de crédito, casa própria, programa minha casa minha vida, SBPE, financiamento com FGTS, consórcio de imóveis, consultoria para financiamento, imóveis no Vale do Paraíba, consultoria para compra de imóvel, aprovação de financiamento rápido, Brasil',
  author: tenant,
  robots: 'index, follow',
};

const images = {
  light: {
    logo: require(`../assets/img/brand/logo512.png`),
    logotext: require(`../assets/img/brand/logotext512.png`),
  },
  dark: {
    logo: require(`../assets/img/brand/logo512negativo.png`),
    logotext: require(`../assets/img/brand/logotext512negativo.png`),
  },
  logo: require(`../assets/img/brand/logotext512.png`),
  logotext: require(`../assets/img/brand/logotext512.png`),
};
images.normal = images.light;

const links = {
  site: `https://www.${site}`,
  instagram: `https://www.instagram.com/${setup.instagram}`,
  mail: `mailto:${setup.email}`,
  whatsapp: `https://api.whatsapp.com/send?phone=55${setup.whatsapp}`,
  phone: `tel:+55${setup.phone}`,
  login: `/login`,
  register: `/register`,
  simule: `/simule`,
  simulador: `/simulador`,
  logo: `https://www.${site}/logotext512.png`,
  telegram: `https://t.me/${setup.telegram}`,
};

const objLinks = {
  app: {
    a: {
      href: `https://app.${site}`,
      class: 'btn outline-secondary',
      aria: 'Acesse nosso aplicativo exclusivo e acompanhe seu financiamento em tempo real',
      targetBlank: true,
      rel: 'noreferrer',
    },
    icon: {
      icon: ui.misc.app.icon,
      class: 'svg14 hovGreen',
      click: null,
      tooltip:
        'Acesse nosso aplicativo exclusivo e acompanhe seu financiamento em tempo real',
    },
    text: {
      content: null,
      class: null,
    },
    bio: {
      text: 'Acesse nosso aplicativo exclusivo e acompanhe seu financiamento em tempo real',
      class: null,
    },
    divClass: 'd-flex flex-row align-items-center',
  },
  instagram: {
    a: {
      href: `https://www.instagram.com/${setup.instagram}`,
      class: 'btn outline-secondary',
      aria: 'Siga nosso Instagram',
      targetBlank: true,
      rel: 'noreferrer',
    },
    icon: {
      icon: ui.social.instagram.icon,
      class: 'svg14 hovGreen',
      click: null,
      tooltip: 'Siga nosso Instagram',
    },
    text: {
      content: null,
      class: null,
    },
    bio: {
      text: 'Siga nosso Instagram',
      class: null,
    },
    divClass: 'd-flex flex-row align-items-center',
  },
  mail: {
    a: {
      href: `mailto:${setup.email}`,
      class: 'btn outline-secondary',
      aria: 'Envie um email',
      targetBlank: false,
      rel: 'noreferrer',
    },
    icon: {
      icon: ui.social.email.icon,
      class: 'svg14 hovGreen',
      click: null,
      tooltip: 'Envie um email',
    },
    text: {
      content: null,
      class: null,
    },
    bio: {
      text: 'Envie um email',
      class: null,
    },
    divClass: 'd-flex flex-row align-items-center',
  },
  whatsapp: {
    a: {
      href: `https://api.whatsapp.com/send?phone=55${setup.whatsapp}`,
      class: 'btn outline-secondary',
      aria: 'Converse pelo WhatsApp',
      targetBlank: true,
      rel: 'noreferrer',
    },
    icon: {
      icon: ui.social.whatsapp.icon,
      class: 'svg14 hovGreen',
      click: null,
      tooltip: 'Converse pelo WhatsApp',
    },
    text: {
      content: null,
      class: null,
    },
    bio: {
      text: 'Converse pelo WhatsApp',
      class: null,
    },
    divClass: 'd-flex flex-row align-items-center',
  },
  phone: {
    a: {
      href: `tel:+55${setup.phone}`,
      class: 'btn outline-secondary',
      aria: 'Ligue para nós',
      targetBlank: false,
      rel: 'noreferrer',
    },
    icon: {
      icon: ui.social.phone.reg.icon,
      class: 'svg14 hovGreen',
      click: null,
      tooltip: 'Ligue para nós',
    },
    text: {
      content: null,
      class: null,
    },
    bio: {
      text: 'Ligue para nós',
      class: null,
    },
    divClass: 'd-flex flex-row align-items-center',
  },
  telegram: {
    a: {
      href: `https://t.me/${setup.telegram}`,
      class: 'btn outline-secondary',
      aria: 'Converse pelo Telegram',
      targetBlank: true,
      rel: 'noreferrer',
    },
    icon: {
      icon: ui.social.telegram.icon,
      class: 'svg14 hovGreen',
      click: null,
      tooltip: 'Converse pelo Telegram',
    },
    text: {
      content: null,
      class: null,
    },
    bio: {
      text: 'Converse pelo Telegram',
      class: null,
    },
    divClass: 'd-flex flex-row align-items-center',
  },
  simulacao: {
    a: {
      href: `https://www.${site}/simulacao`,
      class: 'btn outline-secondary',
      aria: 'Simule seu financiamento agora',
      targetBlank: true,
      rel: 'noreferrer',
    },
    icon: {
      icon: ui.etapas.simulacao.icon,
      class: 'svg14 hovGreen tran05 growSvg16',
      click: null,
      tooltip: 'Simule seu financiamento agora',
    },
    text: {
      content: null,
      class: null,
    },
    bio: {
      text: 'Simule seu financiamento agora',
      class: null,
    },
    divClass: 'd-flex flex-row align-items-center',
  },
  site: {
    a: {
      href: `https://www.${site}`,
      class: 'btn outline-secondary',
      aria: 'Acesse nosso site',
      targetBlank: true,
      rel: 'noreferrer',
    },
    icon: {
      icon: ui.social.site.icon,
      class: 'svg14 hovGreen',
      click: null,
      tooltip: 'Acesse nosso site',
    },
    text: {
      content: null,
      class: null,
    },
    bio: {
      text: 'Acesse nosso site',
      class: null,
    },
    divClass: 'd-flex flex-row align-items-center',
  },
};

objLinks.simule = {
  ...objLinks.simulacao,
  a: {
    ...objLinks.simulacao.a,
    href: `https://www.${site}/simule`,
  },
};

const bio = {
  tenant,
  image: links.logo,
  items: [
    'site',
    'whatsapp',
    'phone',
    'mail',
    'instagram',
    'simulacao',
    'app',
  ],
};

const favicon = `https://www.${site}/favicon.ico`;

const colors = {
  black: '#000000',
  darkest: '#02071b',
  darker: '#070d24',
  dark: '#0d142f',
  grafitte: '#393e46',
  blue: '#3a4776',
  bluer: '#007fd4',
  lightBlue: '#007fd4',
  yellow: '#f3c82e',
  gold: '#d1bb57',
  gray: '#cccccc',
  grey: '#f0f0f0',
  white: '#ffffff',
  bs: {
    blue: '#1e337c',
    indigo: '#6610f2',
    purple: '#6f42c1',
    pink: '#d63384',
    red: '#aa0000',
    orange: '#fd7e14',
    yellow: '#ffbb00',
    green: '#2cbe00',
    teal: '#00a372',
    cyan: '#007fd4',
    black: '#000',
    white: '#fff',
    gray: {
      100: '#f8f9fa',
      200: '#e9ecef',
      300: '#dee2e6',
      400: '#ced4da',
      500: '#adb5bd',
      600: '#6c757d',
      700: '#495057',
      800: '#343a40',
      900: '#212529',
    },
  },
};

const CONF = {
  brand,
  config,
  colors,
  headline,
  images,
  links,
  objLinks,
  meta,
  setup,
  bio,

  tenant,
  company,
  favicon,
  site,
  domain: window.location.hostname,
  slash: '/',
  ORA,
  BWAPI,
};

export default CONF;

export {
  bio,
  brand,
  config,
  images,
  links,
  objLinks,
  setup,
  tenant,
  company,
  site,
};
